<template>
  <div>
    <Navigation/>
    <v-container>
      <v-alert v-if="errorMessage" dense border="top" color="error" dark>
        {{ errorMessage }}
      </v-alert>
      <v-btn v-if="!openPricesAndParameters && !loadingCreate" block color="primary" @click="openPricesAndParameters=true">{{ selectedLanguage['newrailway_change_params'] }}</v-btn>
      <v-btn v-if="!openPricesAndParameters && loadingCreate" style="pointer-events: none;" block color="primary" @click="openPricesAndParameters=true">{{ selectedLanguage['newrailway_change_params'] }}</v-btn>
      <v-card v-if="openPricesAndParameters">
        <v-card-text>
          <PricesAndParametersTable configType="roadway"/>
          <br>
          <v-row justify="center">
            <v-btn :color="color" dark text @click="openPricesAndParameters=false">{{ selectedLanguage['createNewRoadway_component_text1'] }}</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <br>
      <v-card v-if="configs.length>0">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <v-card-title>{{ selectedLanguage['createNewRoadway_component_text2'] }}</v-card-title>
          <v-tooltip max-width="600" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
              <v-icon style="margin-left: 10px;">
                mdi-information-outline
              </v-icon>
              </v-btn>
            </template>
            <span>{{ selectedLanguage['help_text_4'] }}</span>
          </v-tooltip>
        </div>
        <v-card-text>
          <form>
            <v-select v-model="configId" clearable :items="configs" item-text="name" item-value="id" :label="selectedLanguage['createNewRoadway_component_text6']"/>
            <v-text-field v-model="title" :label="selectedLanguage['createNewRoadway_component_text3']" required/>
            <v-text-field v-model="description" :label="selectedLanguage['createNewRoadway_component_text4']"/>
            <v-text-field v-model="owner" :label="selectedLanguage['createNewRoadway_component_text5']"/>
            <v-row justify="center">
              <v-btn v-if="configId" :loading="loadingCreate" color="primary" @click="newCalc()">{{ selectedLanguage['createNewRoadway_component_text7'] }}</v-btn>
              <v-btn :color="color" dark text @click="$router.push('/')">{{ selectedLanguage['createNewRoadway_component_text8'] }}</v-btn>
            </v-row>
          </form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Navigation from '../../Navigation'
  import PricesAndParametersTable from '../PricesAndParametersTable'
  import { portuguese } from '../../../languages/pt-br.js'
  import { english } from '../../../languages/en'
  
  export default {
    name: 'CreateNewRoadway',
    components:{Navigation, PricesAndParametersTable},
    computed: {
        helper(){
        return this.$store.getters.helper
      },
      configs () {
        var config = this.$store.getters.configsByType('roadway')
        for(let i = 0; i < config.length; i++){
          if(config[i].deprecated === true  || config[i].language != this.profile['language']){
            delete config[i]
          }else{
            //nothing
          }
        }
        return config
      },
      ...mapGetters(['profile']),
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
    },
    async mounted(){
      await this.getConfigs()
      await this.initTittleDesc()
    },
    data: () => ({
      configId: undefined,
      openPricesAndParameters: false,
      errorMessage: undefined,
      loadingCreate: false,
      title: 'Roadway project',
      description: 'Roadway project description',
      owner: "",
      type: 1,
      color: '#0A7373',
      baseLayer: undefined,
      sendLanguage: 'pt-br',
      languagesItems: [
        'pt-br',
        'en'
      ],
    }),
    watch: {
      async currency (val) {
        this.currency = val
        this.exchange = this.exchanges[this.currency]
      },
      configId:{
        handler(){
          if(this.configId != undefined){
            for(let i=0;i<this.configs.length;i++){
              if(this.configs[i]['id'] != undefined){
                if(this.configs[i]['id'] == this.configId){
                  this.description = this.configs[i]['name']
                }
              }
            }
          }
        }
      }
    },
    methods: {
      initTittleDesc(){
        this.title = this.selectedLanguage['newRoadway_title']
        this.description = this.selectedLanguage['newRoadway_description']
        this.owner = this.profile['first_name']
      },
      ...mapActions(['createCalc', 'changeStatus','getConfigs']),
      async newCalc(){
        this.errorMessage = undefined
        this.loadingCreate = true
        const payloadCalc = {
          "description": this.description,
          "title": this.title,
          "company": this.owner,
          "refers_to": "",
          "code": "",
          "continent": "",
          "type_id": this.configId
        }
        const res = await this.createCalc(payloadCalc)

        if(res.id){
          await this.changeStatus({calcId: res.id, payload: {"status": "Init", "step_name": "Adjust prices", "step": 2}})
          this.$router.push('/type/roadway/'+res.id)
        }
        else{
          this.errorMessage = res
        }
        this.loadingCreate = false
      },
    },
  }
</script>

<style scoped>
.border {
  border: 2px dashed orange;
}
</style>
