<template>
    <v-data-table disable-pagination :headers="selectedLanguage['projectCreate_userParams_railway_change_header']" :items="configs" sort-by="name" class="elevation-0" :hide-default-footer="true">
      <template v-slot:item.name="props"> 
        <v-edit-dialog color="primary" :return-value.sync="props.item.name" @save="changeNameSave(props.item)" large>
          {{ props.item.name }}
          <template v-slot:input>
            <v-text-field v-model="props.item.name" label="Edit" single-line></v-text-field>
          </template>
        </v-edit-dialog>

      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <div style="display: flex; flex-direction: column;">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <v-toolbar-title>{{ selectedLanguage['projectCreate_userParams_railway_change_card_tittle'] }}</v-toolbar-title>
              <v-tooltip max-width="600" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
                  <v-icon style="margin-left: 10px;">
                    mdi-information-outline
                  </v-icon>
                  </v-btn>
                </template>
                <span>{{ selectedLanguage['help_text_14'] }}</span>
              </v-tooltip>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                {{ selectedLanguage['projectCreate_userParams_railway_change_card_btn_newItem_tittle'] }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ selectedLanguage['projectCreate_userParams_railway_change_card_btn_newItem_tittle'] }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-alert v-if="dialogErrorMessage" dense border="top" color="error" dark>
                    {{ dialogErrorMessage }}
                  </v-alert>
                  <v-row>
                    <v-col>
                      <v-select v-model="defaultId" clearable :items="formConfigs" item-text="name" item-value="id" :label="selectedLanguage['projectCreate_userParams_railway_change_card_select_lable']"/>
                    </v-col>
                  </v-row>
                  <v-row v-if="defaultId">
                    <v-col>
                      <v-text-field v-model="configTitle" :label="selectedLanguage['projectCreate_userParams_railway_change_cardNew_projTittle_tittle']" required/>
                    </v-col>
                  </v-row>
                  <v-row v-if="defaultId">
                    <v-col>
                      <v-select v-model="currency" :items="Object.keys(exchanges)" :label="selectedLanguage['projectCreate_userParams_railway_change_cardNew_curency_tittle']" required/>
                    </v-col>
                    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                      <v-col>
                        <v-text-field v-model="exchange" :label="selectedLanguage['projectCreate_userParams_railway_change_cardNew_currencyRef_tittle']" required/>
                      </v-col>
                      <v-tooltip max-width="600" right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
                          <v-icon style="margin-left: 10px;">
                            mdi-information-outline
                          </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ selectedLanguage['help_text_11'] }}</span>
                      </v-tooltip>
                    </div>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  {{ selectedLanguage['btn_cancel_title'] }}
                </v-btn>
                <v-btn v-if="defaultId" :loading="loadingCustomized" color="primary" @click="newCustomized()">
                  {{ selectedLanguage['projectCreate_userParams_railway_change_cardNew_btn_name2'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogCopy" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">{{ selectedLanguage['projectCreate_userParams_railway_change_cardCopy_tittle'] }}</v-card-title>
              <v-card-text>
                <v-text-field v-model="copyName" :label="selectedLanguage['projectCreate_userParams_railway_change_cardCopy_field_lable']" required/>
                <v-row>
                  <v-col v-if="editedItem">
                    <v-select v-model="copyCurrency" :items="Object.keys(exchanges)" :label="selectedLanguage['projectCreate_userParams_railway_change_cardNew_curency_tittle']" required/>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="copyExchange" :label="exchangeFrom" required/>
                  </v-col>
                </v-row>
                <v-alert v-if="dialogErrorMessage" dense border="top" color="error" dark>
                  {{ dialogErrorMessage }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeCopy">{{ selectedLanguage['btn_cancel_title'] }}</v-btn>
                <v-btn :loading="dialogLoading" color="blue darken-1" text @click="copyItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">{{ selectedLanguage['projectCreate_userParams_railway_change_cardCopy_delete_field_lable'] }}</v-card-title>
              <v-card-text>
                <v-alert v-if="dialogErrorMessage" dense border="top" color="error" dark>
                  {{ dialogErrorMessage }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">{{ selectedLanguage['btn_cancel_title'] }}</v-btn>
                <v-btn :loading="dialogLoading" color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="updateDefaults(item)" v-if="item.deprecated === true">
          mdi-alert-outline
        </v-icon>
        <v-icon small class="mr-2" @click="copyItem(item)">
          mdi-content-copy
        </v-icon>
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>        
      </template>
    </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { portuguese } from '../../languages/pt-br'
  import { english } from '../../languages/en'

  export default {
    name: 'PricesAndParametersTable',
    props: {
      configType: undefined,
    },
    mounted: async function(){
      this.exchange = this.exchanges[this.currency]

      var configs = this.configDefaults

      this.formConfigs = configs
    },
    data: () => ({
      setParamConfigTitle: undefined,
      formConfigs: undefined,
      headerTitle1: undefined,
      configId: undefined,
      defaultId: undefined,
      currency: 'BRL',
      exchange: undefined,
      configTitle: 'My customized configuration 1',
      dialog: false,
      dialogCopy: false,
      dialogDelete: false,
      copyName: undefined,
      loadingCustomized: false,
      dialogErrorMessage: undefined,
      dialogLoading: false,
      copyCurrency: undefined,
      copyExchange: 1,
      max25chars: v => v.length <= 25 || 'Input too long!',
      editedIndex: -1,
      editedItem: {
        name: '',
        currency: 'USD',
      },
      defaultItem: {
        name: 'Default config',
        currency: 'USD',
      },
    }),
    computed: {
      ...mapGetters(['configDefaultsById','configById','exchanges','profile']),
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
      configs () {
        return this.$store.getters.configsByType(this.configType)
      },
      configDefaults () {
        return this.$store.getters.configDefaultsByType(this.configType)
      },
      exchangeFrom () {
        return 'Exchange (from '+ this.editedItem.currency +')'
      },
    },
    methods: {
      ...mapActions(['createConfig','deleteConfig','getConfigs','duplicateConfig','updateConfig']),
      async changeNameSave (item) {
        let res = await this.updateConfig({'id': item.id, 'name': item.name})
        if(res!=true){
          this.errorMessage = res
        }
      },
      async newCustomized(){
        this.errorMessage = undefined
        this.loadingCustomized = true
        if(this.defaultId){
          let payloadConfig = this.configDefaultsById(this.defaultId)
          let payload = JSON.parse(JSON.stringify(payloadConfig));
          payload['name'] = this.configTitle
          payload['currency'] = this.currency
          payload['tax'] = this.exchange
          payload['type_id'] = this.defaultId
          payload['language'] = this.profile['language']
          let res = await this.createConfig(payload)
          if(res.id){
            this.defaultId = undefined
            this.configId = res.id
          }
          else{
            this.errorMessage = res
          }
          res = await this.getConfigs()
          if(res!=true){
            this.errorMessage = res
          }
          this.configTitle = 'My customized configuration 1'
        }
        this.loadingCustomized = false
        this.dialog = false
      },
      copyItem (item) {
        this.editedIndex = this.configs.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.copyName = this.editedItem.name+' copy'
        this.copyCurrency = this.editedItem.currency
        this.dialogCopy = true
      },
      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.$router.push('/edit/'+this.configType+'/'+this.editedItem.id)
      },
      updateDefaults (item) {
        this.editedItem = Object.assign({}, item)
        this.$router.push('/update/'+this.configType+'/'+this.editedItem.id)
      },
      deleteItem (item) {
        this.editedIndex = this.configs.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.dialogLoading = true
        this.dialogErrorMessage = undefined
        const res = await this.deleteConfig(this.editedItem.id)
        if(res==true){
          let res = await this.getConfigs()
          if(res!=true){
            this.dialogErrorMessage = res
          }
          this.dialogDelete = false
        }
        else{
          this.dialogErrorMessage = res
        }
        this.dialogLoading = false
      },
      async copyItemConfirm () {
        this.dialogLoading = true
        this.dialogErrorMessage = undefined
        var payload = {}
        payload['user_type_id'] = this.editedItem.id
        payload['currency'] = this.copyCurrency
        payload['tax'] = this.copyExchange
        payload['name'] = this.copyName
        let res = await this.duplicateConfig(payload)
        if(res==true){
          res = await this.getConfigs()
          if(res!=true){
            this.dialogErrorMessage = res
          }
          this.dialogCopy = false
          this.copyName = undefined
          this.copyCurrency = undefined
        }
        else{
          this.dialogErrorMessage = res
        }
        this.dialogLoading = false
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeCopy () {
        this.dialogCopy = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.configs[this.editedIndex], this.editedItem)
        } else {
          this.configs.push(this.editedItem)
        }
        this.close()
      },
    },
    watch: {
      currency: {
        handler(val, oldVal) {
          if(val!=oldVal){
            this.exchange = this.exchanges[val]
          }
        }
      },
      copyCurrency: {
        handler(val, oldVal) {
          if(val!=oldVal){
            let toUSD = this.exchanges[this.editedItem.currency]
            this.copyExchange = (this.exchanges[val]/toUSD).toFixed(2)
          }
        }
      },
      defaultId:{
        handler(){
          if(this.defaultId != undefined){
            for(let i = 0; i < this.formConfigs.length; i++){
              if(this.formConfigs[i]['id'] == this.defaultId){
                this.configTitle = this.formConfigs[i]['name']
              }
            }
          }
        }
      },
    }
  }
</script>